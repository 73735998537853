/* table {
  border-collapse: inherit;
  border-spacing: 0 10px;
} */
/* tr {
  background-color: '#272E42';
} */
/* th {
  background-color: green;
  color: white;
  flex-grow: 1;
  border: 1px solid;
} */
th,
td {
  /* text-align: center; */
  /* border: 1px solid black; */
  padding: 10px;
  /* flex-grow: 1; */
}

th:first-child {
  border-left-style: rgba(0, 0, 0, 0.3);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
th:last-child {
  border-right-style: rgba(0, 0, 0, 0.3);
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
td:first-child {
  border-left-style: rgba(0, 0, 0, 0.3);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
td:last-child {
  border-right-style: rgba(0, 0, 0, 0.3);
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

tr:hover {
  background-color: #272e42;
}
tr {
  margin-top: 20px;
}
.roles__round {
  position: relative;
  width: 30px;
  height: 30px;
}

.roles__round input[type="checkbox"] {
  visibility: hidden;
}
/* .roles__round input[type='checkbox']:checked + label {
  background-color: #0fbcf9;
  border-color: #0fbcf9;
} */
.roles__round input[type="checkbox"]:checked + label {
  background-color: transparent;
  border-color: #ccc;
}
.roles__round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.roles__round label {
  background-color: transparent;
  border: 2px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
}
.roles__round label:after {
  border: 2px solid rgb(36, 207, 36);
  border-top: none;
  border-right: none;
  content: "";
  height: 7px;
  left: 8px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}
.role__Table {
  color: #ffffff !important ;
  opacity: 0.4;
}
.role__Table:hover {
  color: #81f5ff !important;
  opacity: 1;
}
.role_selected_user {
  color: #81f5ff !important;
  opacity: 1;
  background-color: #272e42;
}
