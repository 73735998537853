.passwordRecovery__background {
  background: url('../../assets/img/19.png') 0% 0% no-repeat padding-box;
  opacity: 1;
  background-color: #020922;
  background-size: cover;
  width: 100%;
  background-position: center;
  height: 100vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.passwordRecovery__d-flex {
  display: flex;
}
.passwordRecovery__justifyBetween {
  justify-content: space-between;
}
.passwordRecovery__header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.passwordRecovery__linkBox {
  /* background-color: red; */
  margin: 20px;
}
.passwordRecovery__link {
  text-decoration: none;
  color: white;
  font-weight: lighter;
}
.passwordRecovery__linkAccount {
  text-decoration: none;
  color: white;
  font-weight: 600;
}
.passwordRecovery__gridItem {
  display: flex;
  justify-content: center;
  align-items: center;
}
.passwordRecovery__gridContentItem {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.passwordRecovery__alignItems {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 350px;
}
.passwordRecovery__width350 {
  width: 350px;
}
.passwordRecovery__margin50 {
  margin-top: 50px;
}
.passwordRecovery__margin30 {
  margin-top: 30px;
}

.passwordRecovery__alignCheckbox {
  display: flex;
  align-items: center;
  padding-top: 20px;
}
.passwordRecovery__footerLayout {
  width: 80%;
  align-self: center;
  height: 10%;
}

.passwordRecovery__gridContentBox {
  width: 50%;
}
.passwordRecovery__label {
  font-size: 14px;
  color: #0fbcf9;
  margin-top: 10px;
  display: block;
  padding: 10px;
}
.passwordRecovery__input {
  border: 2px solid #bce0fd;
  border-radius: 50px;
  padding: 15px 20px;
  width: 360px;
  /* height: 50px; */
  background: #f1f9ff 0% 0% no-repeat padding-box;
  /* padding-left: 20px; */
  /* padding-bottom: 5px; */
}
.passwordRecovery__input:focus {
  outline: none;
}
.passwordRecovery__input::placeholder {
  padding-bottom: 10px;
  font-size: 14px;
  color: #000;
  vertical-align: sub;
  opacity: 0.5;
}

.passwordRecovery__round {
  position: relative;
}
.passwordRecovery__round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
}
.passwordRecovery__round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 7px;
  left: 8px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}
.passwordRecovery__round input[type='checkbox'] {
  visibility: hidden;
}
.passwordRecovery__round input[type='checkbox']:checked + label {
  background-color: #0fbcf9;
  border-color: #0fbcf9;
}
.passwordRecovery__round input[type='checkbox']:checked + label:after {
  opacity: 1;
}
.passwordRecovery__cheackbox_text {
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  margin-left: 30px;
  margin-top: 10px;
}
.passwordRecovery__forgotlink {
  color: #b4b4b4;
  font-size: 14px;
}
.passwordRecovery__footerLink {
  text-decoration: none;
  color: #fff;
  padding-right: 30px;
  padding-left: 30px;
}

.passwordRecovery__footerBorderLink {
  border-right: 2px solid white;
}
.passwordRecovery__error_msgBody {
  position: relative;
  background-color: #f95454;
  width: 250px;
  /* height: 50px; */
  left: 20px;
  bottom: 15px;
  border-radius: 10px;
}
.passwordRecovery__error {
  display: flex;
  align-items: flex-end;
  position: 'absolute';
  right: 0;
  margin-top: 60px;
}
.passwordRecovery__error_text {
  font-size: 14px;
  /* text-align: center; */
  align-content: center;
  padding: 10px 10px;
  /* margin-bottom: 0; */
}
.passwordRecovery__resentSms {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}
.passwordRecovery__resentLink {
  text-decoration: none;
  color: #008fff;
  font-weight: 700;
  font-size: 20px;
}
.passwordRecovery__radioInput {
  padding-left: 10px;
}
/* .passwordRecovery__form-control {
  font-family: system-ui, sans-serif;
  margin-left: 10px;
  color: #008cf2;
  font-weight: bold;
} */
/* .passwordRecovery */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.passwordRecovery__capetchaBox {
  margin-top: 30px;
  background-color: white;
  border-radius: 10px;
}
.passwordRecovery__captcha_img {
  width: 100%;
  padding: 10px 20px;
}

.passwordRecovery__capetchaTitle {
  color: black;
  padding-left: 20px;
  font-size: 14px;
}
