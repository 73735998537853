.resetPassword__input {
  border: 1px solid #008cf2;
  border-radius: 20px;
  height: 40px;
  min-width: 400px;
  background-color: transparent;
  color: white;
  letter-spacing: 4px;
  padding: 0px 20px;
}
.resetPassword__input::placeholder {
  color: white;
  opacity: 0.6;
}
.resetPassword__input:focus {
  border: 'none';
}
