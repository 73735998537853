.cloud-main-box {
    background-color: black;
    min-height: 560px;
    width: 100%;
    border: 1px solid rgba(255,255,255,0.2);
    position: relative;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    overflow-y: hidden;
}

.cloud-main-box::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #0000;
    border-radius: 3;
    border: 0px solid #0000;
  }
  
/* Add a thumb */
.cloud-main-box::-webkit-scrollbar-thumb {
    background: #ADD8E6;
    width: 10;
    height: 5;
}
.cloud-loader {
    border: 1px solid rgba(255,255,255,0.5);
    height: 120px;
    border-radius: 10px;
    position: relative;
}
.react-pdf__Page__annotations{
    display: none;
}
.cloud-ass-table_head {
    background-color: #272E42;
    height: 40px;
    width: 100%;
    color: white;
    font-size: 22px;
   
}
.cloud_ass_table {
    border-collapse:collapse !important;
}
.ca_title {
    padding-left: 90px;
    padding-right: 90px;
}
.cs_data {
    text-align: center;
    padding-top: 25px;
    font-size: 18px;
    opacity: 0.7;
}
.cs-row:hover {
    background-color:#272E42;
    color: #81F5FF;
    cursor: pointer;
}
.cloud-pdf-view {
    border: 1px solid  #707070;
    border-radius: 30px;
    overflow-y: hidden;
    padding: 30px;
    height: 500px;
    display: flex;
    justify-content: flex-start;
}
.cloud-no-pdf-view-grid{
    border: 1px solid  #707070;
    border-radius: 30px;
    overflow-y: hidden;
    padding: 30px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.cloud-pdf-view-grid{
    border: 1px solid  #707070;
    border-radius: 30px;
    overflow-y: hidden;
    padding: 30px;
    height: 500px;
    
}
.cloud-main-grid{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cloud-list-view {
    border: 1px solid  #707070;
    border-radius: 30px;
    overflow-y: hidden;
    padding:90px 265px 10px 265px;
}
.page_data {
    text-align: center; 
    padding-top: 10px;
    margin-left:10px;
    margin-right: 10px;
}
.pdf-view-block {
    display: flex;
    margin-top:10px;
    margin-left: 30px;
}
.pdf-box {
    width: 250px;
    margin: auto;
}

@media only screen and (max-width: 768px) {
    .cloud-no-pdf-view-grid {
        margin-top: 20px !important;
    }
  }