.wrapper{
   
    margin: auto;
    width: 70%;
   background: #010A26 0% 0% no-repeat padding-box;
   border: 1px solid #707070;
   border-radius: 30px;
   opacity: 1;  
   padding: 20px;
}
.header{
    display:flex !important;
    flex-direction:row !important;
    justify-content: space-between;
    align-items: center !important;
    /* margin-top:30px; */
    width: 95% !important;
    margin-left:0px !important;
}

.heading{
     color: #FF9900;
     font-size: 30px !important;
     font-weight: bolder !important;
     
}
.headingpadding{
    color: #FF9900;
     font-size: 30px !important;
     font-weight: bolder !important;
    padding-left: 16px
}

.input{
    margin-left:80px;
    width: 500px !important;
    height: 50% !important;
   background: #272E42 0% 0% no-repeat padding-box !important;
   border: 1px solid #000000 !important;
   border-radius: 10px !important;
  opacity: 1;
  display: flex !important;
  flex-direction: row !important;

}.input1{
    margin-left:80px;
    width: 500px !important;
    height: 50% !important;
   background: #272E42 0% 0% no-repeat padding-box !important;
   border: 1px solid #000000 !important;
   border-radius: 10px !important;
  opacity: 1;
  display: flex !important;
  flex-direction: row !important;
}
.in{
    background: #272E42 0% 0% no-repeat padding-box;
    color:white;
    outline: none;
    border: none;
    padding-left: 10px;
    font-size: 18px;
    margin-top: "15px";
    margin-bottom:"5px";
}
.setting{
    margin-left: -75px !important;
    margin-top: 5px;
    /* margin-right: 200px; */
}
.boxx{
    width:90% !important;
   height: 50% !important;
   margin-top:30px;
    margin-left:60px;
    padding-left: -60px;
}
.boxx1{
    padding-left:60px;
    width: 18% !important;
    height: 100%;
}
.boxx2{
    margin-left: 100px !important;
        width: 30% !important;
        height: 100%;
}
.boxx3{
    margin-left: 60px;
    width: 20% !important;
    height:100%;
}
.boxx4{
    width: 35% !important;
    height: 100%;
    margin-right: -50px !important;
}

.ForwardRef-iconButton-6{
    display: none !important;
}