.CloudInfoChart__background {
  background: url("../../assets/img/19.png") 0% 0% no-repeat padding-box;
  opacity: 1;
  background-color: #020922;
  background-size: cover;
  width: 100%;
  background-position: center;
  min-height: 100vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CloudInfoChart__marginTopLeft {
  position: absolute;
  left: 8rem;
  top: 80px;
}
.zoomChart {
  width: 600px;
  height: 600px;
}
.zoomChartBox {
  margin-top: 110px;
}
.zoomChartTitle {
  font-size: 28px;
}
.Services {
  font-size: 26px;
}
@media only screen and (min-width: 960px) {
  .CloudInfoChart__marginTopLeft {
    position: absolute;
    left: 2.5rem;
    top: 94px;
  }
  .zoomChart {
    width: 400px;
    height: 400px;
  }
  .zoomChartBox {
    margin-top: 80px;
  }
  .zoomChartTitle {
    font-size: 28px;
  }
  .Services {
    font-size: 26px;
  }
}
@media only screen and (min-width: 1080px) {
  .CloudInfoChart__marginTopLeft {
    position: absolute;
    left: 2.5rem;
    top: 94px;
  }
  .zoomChart {
    width: 400px;
    height: 400px;
  }
  .zoomChartBox {
    margin-top: 80px;
  }
  .zoomChartTitle {
    font-size: 28px;
  }
  .Services {
    font-size: 26px;
  }
}
@media only screen and (min-width: 1152px) {
  .CloudInfoChart__marginTopLeft {
    position: absolute;
    left: 4rem;
    top: 80px;
  }
  .zoomChart {
    width: 500px;
    height: 500px;
  }
  .zoomChartBox {
    margin-top: 110px;
  }
}

@media only screen and (min-width: 1309px) {
  .CloudInfoChart__marginTopLeft {
    position: absolute;
    left: 5rem;
    top: 80px;
  }
}

@media only screen and (min-width: 1440px) {
  .CloudInfoChart__marginTopLeft {
    position: absolute;
    left: 8rem;
    top: 80px;
  }
}

@media only screen and (min-width: 1600px) {
  .CloudInfoChart__marginTopLeft {
    position: absolute;
    left: 12.5rem;
    top: 80px;
  }
}

@media only screen and (min-width: 1800px) {
  .CloudInfoChart__marginTopLeft {
    position: absolute;
    left: 17rem;
    top: 80px;
  }
}

@media only screen and (min-width: 1920px) {
  .CloudInfoChart__marginTopLeft {
    position: absolute;
    left: 20.5rem;
    top: 80px;
  }
}
.donutchart_aws {
  position: relative;
  width: 100%;
  height: 100%;
}
.don_label_chart {
  position: absolute;
  width: 100%;
  height: 100%;
}
.don_label_chart_labal{
  margin-left: 148px;
}
.don_label_chart {
  position: relative;
  width: 100%;
  height: 100%;
}
@media (max-width:1440px) {
 
  .date_label_resp {
    margin-right: -15px !important;
  }
  .date_label_resp2 {
    margin-right: -15px !important;
  }
  .date_label_resp3 {
    margin-left: -24.8px !important;
  }
  .date_label_resp4 {
    margin-left: -11px !important;
  }

}
@media only screen and (max-width: 1244px) {
 
  .donutchart_aws {
    width:320px !important;
    height: 320px !important;
    position:absolute !important;
    margin-left: -10px !important;
  }
  .don_label_chart {
    position: absolute !important;
    /* top: 8% !important; */
    right: 44% !important;
}
  .don_label_1 {
    /* font-size: 13px !important; */
    margin-left: 40% !important ;
  }
  .donut_chart_text_data {
    font-size: 20px !important;
  }
  .donut_chart_data_resp {
    position: relative !important;
    left: 25% !important;
  }
  .donut_chart_text_data2 {
    font-size: 20px !important;
  }
    .don_label_chart_labal {
      margin-left: 65px;
    }
}

@media only screen and (max-width: 1052px) {
 
  .first_grid_container_resp {
    width: 52% !important;
  }
  .cards_grid_container {
    width: 46% !important;
  }
}

@media only screen and (max-width: 824px) {
  .donutchart_aws {
    width:370px !important;
    height: 370px !important;
    position:absolute !important;
    margin-left: -10px !important;
  }
  .full_box_container {
    flex-direction: column !important;
  }
  .first_grid_container_resp {
    width: 100% !important;
  }
  .cards_grid_container {
   width: 100% !important;
   margin-top: 40px !important;
   margin-bottom: -40px !important;
  }
  .donutchart_aws {
    margin-left: 70px !important;
  }
  .don_label_chart {
   right: 13% !important;
  }
  .don_label_chart_labal {
    margin-left: 163px;
  }
}




