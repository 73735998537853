.AceessanalysisS3__background {
    background: url('../../../assets/img/19.png') 0% 0% no-repeat padding-box;
    opacity: 1;
    background-color: #020922;
    background-size: cover;
    /* width: 100%; */
    background-position: center;
    min-height: 100vh;
    color: #fff;
    display: flex;
    flex-direction: column;
  }
  .AceessanalysisS3leftbox{
      background: rgba(0, 0, 0, 0);
      padding:0 !important;
      margin-top: 50px;
      border-radius: 20px;
      width: 100%;
  }
  .AceessanalysisS3rightbox{
      background: rgba(0,0,0,0.3);
      padding:46px 24px 52px 28px !important;
      margin-left: 50px !important;
      margin-top: 50px;
      border-radius: 10px;
  }
  .AceessanalysisS3leftboxtext{
      color: rgba(255,255,255,0.4) !important;
  }
  .AceessanalysisS3leftboxtext:hover{
      color: #fff !important;
      background: #272E42;
      border-radius: 20px;
  }
  .s2bucket_filter_sections {
    flex-wrap: wrap !important;
    height: 62px !important;
    width: 100% !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    overflow-y: auto !important;
    /* overflow-x: auto !important; */
    background: #272E42;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 37px;
    margin-top: 28px;
  }
  @media screen and (max-width:1024px) {
    .acc_analysis_s3bucket_table_font {
        font-size: 16px !important;
        width: 85px !important;
    }
    .acc_filter_label_title {
        font-size: 15px !important;
    }
   }

@media screen and (max-width:768px) {
.acc_analysis_s3bucket_table_font {
    font-size: 14px !important;
    width: 0px !important;
 }
 .acc_table_sort_label_icon {
    width: 165px !important;
 }
 .acc_filter_label_title {
     font-size: 12px !important;
     margin-top: 5px !important;
     margin-bottom: 5px !important;
 }
 .acc_ana_s3bucket_select {
    font-size: 14px !important;
    margin-top: 5px !important
 }
 .s3_bucket_reficon {
     margin-left: 60% !important;
 }
 .s3_refresh_img {
    width: 25px !important;
    height: 25px !important;
    margin-top: 5px !important;
 }
}
  


  