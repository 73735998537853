svg {
  display: inline-block;
  vertical-align: middle;
}
.circle {
  background-color: #d83b01;
  border-radius: 50%;
  color: #fff;
  height: 2.5em;
  position: relative;
  width: 2.5em;

  border: 1px solid transparent;
}

.circleText {
  text-align: center;
  height: 50%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.hover {
  background-color: #0078d4;
}
.highcharts-button-box{
    fill: #0000;
  
}
.highcharts-button-symbol{
  height: 20px;
  transform:translate(0,0) !important; 
}

.highcharts-credits{
  display: none;
}