.cloud-ass-name {
   width: 120px;
   font-size: 22px;
   font-weight: 500;
}
.enter-create-report-field {
    width: 240px ;
    height: 40px;
    border-radius: 5px;
    border:1px solid #008CF2;
    margin-top: 10px;
    padding: 10px 5px 10px 5px;
    background-color: black;
    color: #fff;
} 
.Name_of_create_report {
    background: #010A26;
    padding: 20px; 
    width: 28%; 
    border-radius: 0px; 
    max-height: 90%; 
    overflow: auto; 
    position: absolute;
}
@media only screen and (max-width: 1258px) {
    .Name_of_create_report {
        width: 35% !important; 
    }
  }
@media only screen and (max-width: 1050px) {
    .Name_of_create_report {
        width: 40% !important; 
    }
  }
@media only screen and (max-width: 768px) {
    .Name_of_create_report {
        width: 50% !important; 
    }
  }