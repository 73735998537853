.container{
    margin-top: 190px;
    margin-left: 200px;
    width: 85%;
    height: 70%;
   background: #010A26 0% 0% no-repeat padding-box;
   border: 1px solid #707070;
   border-radius: 30px;
   opacity: 1;
  
}
.header{
    display:flex !important;
    flex-direction:row !important;
    justify-content: space-between;
    margin-top:30px;
    margin-left:60px;
    width:90%;
    height:15%;
}

.heading{
     color: #FF9900;
     font-size: 30px !important;
     font-weight: bolder !important;
     padding-left: 30px;
}


.input{
    margin-left:150px;
    width: 60% !important;
   height: 50% !important;
   background: #272E42 0% 0% no-repeat padding-box !important;
   border: 1px solid #000000 !important;
   border-radius: 10px !important;
  opacity: 1;
  display: flex !important;
  flex-direction: row !important;
}
/* .in{
    background: #272E42 0% 0% no-repeat padding-box;
    color:white;
    outline: none;
    border: none;
    padding-left: 10px;
    width: 80% !important;
    height: 100% !important;
    font-size: 18px;
    margin-top: "15px";
    margin-bottom:"5px";
} */
.setting{
    margin-left: -100px;
    margin-top: 5px;
}
.box{
    width:90%;
   height: 50%;
   margin-top:30px;
    margin-left:60px;
}
.box1{
    width: 15% !important;
    height: 50%;
}
.box2{
    width: 15% !important;
    height: 50%;
}
.box3{
        width: 15% !important;
        height: 50%;
}
.box4{
    padding-left: 10px !important;
    width: 15% !important;
    height: 50%;
}
.box5{
    width: 30% !important;
    height: 50%;
}