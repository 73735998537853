
@media only screen and (max-width: 768px) {

    .tab_cloudas_resp {
        font-size: 20px !important;
        text-align: center !important;
    } 
    .tab_iam_resp {
        font-size: 20px !important;
    }
    .iam_box_design {
        margin-left: 38px !important;
        margin-right:-1px !important;
      }
  }