.main{
    margin-top: 190px;
    margin-left: 200px;
    width: 85%;
    height: 70%;
   background: #010A26 0% 0% no-repeat padding-box;
   border: 1px solid #707070;
   border-radius: 30px;
   opacity: 1;  
   display: flex;
   flex-direction: column;
}

.header1{
    display:flex !important;
    flex-direction:row !important;
    justify-content: space-between;
    margin-top:30px;
    margin-left:60px;
    width:90%;
    height:15%;
}

.heading1{
     color: #FF9900;
     font-size: 30px !important;
     font-weight: bolder !important;
}


.input{
    margin-left:80px;
    width: 60% !important;
    height: 50% !important;
   background: #272E42 0% 0% no-repeat padding-box !important;
   border: 1px solid #000000 !important;
   border-radius: 10px !important;
  opacity: 1;
  display: flex !important;
  flex-direction: row !important;

}
.in{
    background: #272E42 0% 0% no-repeat padding-box;
    color:white;
    outline: none;
    border: none;
    padding-left: 10px;
    font-size: 18px;
    margin-top: "15px";
    margin-bottom:"5px";
}
.setting1{
    margin-left: 80px;
}
.bo{
    width:90% !important;
   height: 45% !important;
   margin-top:30px;
    margin-left:60px;
    padding-left: -60px;
    display: flex;
    flex-direction: row;
}
.bo1{
    width: 15% !important;
    height: 50%;
}
.bo2{
    width: 30% !important;
    height: 50%;
}
.bo3{
        width: 10% !important;
        height: 50%;
}
.bo4{
    padding-left: 10px !important;
    width: 10% !important;
    height: 50%;
}
.bo5{
    width: 25% !important;
    height: 50%;
}