.SupportCenter_Sub_Box_text {
  font-size: 30px !important;
  font-weight: bold !important;
  padding: 5px 0px 0 0;
  border-bottom: 1px solid #707070;
}

.create_Case_Sub_Box_Dactive_text {
  font-size: 30px !important;
  font-weight: bold !important;
  padding: 5px 0px 0 0;
  border-bottom: 1px solid #707070;
}

.SupportCenter_Sub_Box_step_text {
  padding-top: 15px;
  font-size: 18px;
}

@media only screen and (max-width: 1124px) {
  .box-fit-width {
    flex-basis: 55% !important;
    max-width: 183px !important;
  }
  .resp_width {
    flex-direction: row;
    /* padding: 100px; */
  }
  .resp_width_next {
    padding: 0px;
  }
  .radio-opt-box {
    margin-left: 100px;
  }
}

@media only screen and (max-width: 1024px) {
  .radio-opt-box {
    margin-left: 80px;
  }
}

@media only screen and (max-width: 908px) {
  .radio-opt-box {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 857px) {
  .SupportCenter_Sub_Box_text {
      font-size: 20px !important;
  }

  .create_Case_Sub_Box_Dactive_text {
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 768px) {
  .Create_Case_sub_heding_text,.Create_Case_sub_heding_text {
    font-size: 12px !important;
  }
  .account_support_pan_detail {
    width: 426px !important;
  }
  .create_case_hello_here_text {
    justify-content: space-between !important;
  }
}
.SupportCenter_Sub_select_color {
  color: #008cf2;
  opacity: 1;
}

.SupportCenter_Sub_unselect_color {
  color: #008cf2;
  opacity: 0.5;
}

.p-bottom-30 {
  padding-bottom: 30px !important;
}

.margin-top-55 {
  margin-top: 55px;
}

.subject-input-text {
  margin-top: 15px;
}

.subject-input-suggestion-text {
  opacity: 0.5;
}

.description-text {
  margin-top: 19px !important;
  font-size: 19px !important;
}

.description-input-box {
  margin-top: 30px !important;
}

.upload-btn {
  border: 1px solid #008cf2;
  padding: 8px 35px;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}

.upload-btn-box {
  width: max-content;
}

.previous-btn {
  border-color: #008cf2;
  color: #008cf2;
  margin-left: 20px;
}

/*--------------- Clear Page Model ---------------------*/
.cancel-icon-box {
  display: flex;
  justify-content: flex-end;
}
.clear-page-model-header-title {
  color: #008cf2;
  font-weight: bold !important;
  font-size: 30px !important;
}

.clear-page-model-warning-box {
  margin-top: 25px;
  display: flex !important;
  flex-direction: row;
  align-items: flex-start !important;
  border: 1px solid #707070;
  padding: 30px 60px;
  border-radius: 30px;
}
.clear-page-model-warning-text {
  color: #ffffff;
  font-size: 22px !important;
  margin-left: 22px !important;
}
.clear-page-model-btn-part {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.cursor-pointer {
  cursor: pointer;
}

.margin-right-25 {
  margin-right: 25px;
}

.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}

.Badge {
  right: -5px;
  top: -15px;
  background: #ffffff52;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-center {
  text-align: center;
}
.clear-page-model-box {
  position: absolute;
  width: 920px;
  background-color: #01081D;
  border: 1px solid #707070;
  padding: 40px 60px;
  border-radius: 30px;
}
@media only screen and (max-width: 938px) {
  .clear-page-model-box {
    width: 620px !important;
  }
}

