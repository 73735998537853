.select_cloud_service_model_detail {
    background: #010A26;
    padding: 40px;
    width: 20%;
    border-radius: 0px;
    max-height: 90%;
    overflow: auto;
}
@media only screen and (max-width: 1414px) {
    .select_cloud_service_model_detail {
        width: 25% !important;
    }
  }
  @media only screen and (max-width: 1162px) {
    .select_cloud_service_model_detail {
        width: 30% !important;
    }
  }
  @media only screen and (max-width: 936px) {
    .select_cloud_service_model_detail {
        width: 35% !important;
    }
  }
  @media only screen and (max-width: 826px) {
    .select_cloud_service_model_detail {
        width: 40% !important;
    }
  }