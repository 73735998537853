.SupportCenter_Box {
  width: 90%;
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 30px;
  padding: 30px;
  border: 1px solid #707070;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
}
.SupportCenter_Sub_Box {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 30px;
  padding: 30px;
  border: 1px solid #707070;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
}
.SupportCenter_Search_Box {
  display: flex;
  background-color: #272e42;
  border-radius: 10px;
  padding: 5px 30px;
  flex: 1;
  margin-top: 15px;
  margin-bottom: 15px;
}
.SupportCenter_Search_Input_Box {
  border: none;
  outline: none;
  padding: 5px;
  width: 100%;
  background-color: transparent;
  color: white;
}
.SupportCenter_heading_text {
  color: #ff8800;
  font-size: 36px !important;
  font-weight: Bold !important;
}
.SupportCenter_Sub_heading_text {
  color: #008cf2;
  font-size: 24px !important;
  font-weight: Bold !important;
}
.d-flex {
  display: flex;
}
.items-center {
  align-items: center !important;
}
.justify-between {
  justify-content: space-between;
}
.SupportCenter_btn {
  border: 1px solid #2975cc;
  border-radius: 20px;
  padding: 0px 15px;
}
.SupportCenter_btn_background_color {
  background-color: #328cf2;
}
.margin-left-20px {
  margin-left: 20px !important;
}
.border_none {
  border: 0px solid #0000 !important;
}
.Open_Support_Cases_No_Data_Error_Text {
  color: #fff;
  font-size: 20px;
  opacity: 0.6;
  font-weight: 300;
}
.text-align-center {
  text-align: center;
}
.text-bold {
  font-weight: bold !important;
}
.margin-top-32 {
  margin-top: 32px;
}
.data_text {
  color: #fff !important;
}
.cursor_pointer {
  cursor: pointer;
}

.SupportCenter_Cases_heading_text {
  color: #fff;
  font-size: 25px !important;
}
.SupportCenter_Case_History_heading_text {
  color: #ff8800;
  font-size: 24px !important;
  font-weight: Bold !important;
}

.SupportCenter_Info_heading_text {
  color: #008cf2;
  font-size: 16px;
}
.margin-top-85 {
  margin-top: 85px;
}
.width_60_percentage {
  width: 60%;
}
.SupportCenter_Case_History_Search_Input_Box {
  border: none;
  outline: none;
  padding: 5px;
  width: 80%;
  background-color: transparent;
  color: white;
}
.SupportCenter_fillterIco {
  margin-right: 25px;
}
.margin-top-10 {
  margin-top: 10px;
}
.TableWithPagination_Table_Body {
  margin-top: 10px !important;
  background: #000000 0% 0% no-repeat padding-box;
  border: 0.5px solid #707070;
  opacity: 0.8;
  min-height: 50px;
}


.text_color_primary {
  color: #008cf2 !important;
}
.TableWithPagination_Pagination_Box {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px 20px 0px 20px;
}
.TableWithPagination_Pagination_Count_Box {
  margin-right: 30px;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #666c7c;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.TableWithPagination_box_margin_right_left {
  margin: 0px 40px;
}

.Create_Case_sub_heding_text {
  opacity: 0.4;
  margin-right: 20px !important;
}
.Create_Case_sub_heding_text_color_primary {
  color: #008cf2;
  margin-right: 20px !important;
}
.justify-content-center {
  justify-content: center;
}
.flex-column {
  flex-direction: column !important;
}
.create_case_sub_box {
  margin: 80px 40px 20px 40px;
}

.font-size-24 {
  font-size: 24px !important;
}
.margin-top-30 {
  margin-top: 30px !important;
}
.full-box-border {
  border: 1px solid #707070;
}
.justify-content-end {
  justify-content: flex-end;
}
.padding_top_bottom_10_15 {
  padding: 10px 15px;
}
.Account_and_billing_box {
  border: 1px solid #707070;
  padding: 20px;
}
.text-color-white {
  color: #fff !important;
  
}

.pointer-cursor {
  cursor: pointer;
}

@media only screen and (max-width: 1124px) {
  .TableWithPagination_box_margin_right_left {
    margin: 0px -25px;
  }
}

@media only screen and (max-width: 1116px) {
  .SupportCenter_Case_History_heading_text {
    padding-left: 40px;
  }

  .cursor_pointer {
    position: relative;
    left: -11%;
  }

  .margin-top-10 {
    padding: 0px 73px 0px 39px;
  }
}

@media only screen and (max-width: 1004px) {
  .SupportCenter_Cases_heading_text {
    font-size: 23px !important;
  }
}

@media only screen and (max-width: 911px) {
  .SupportCenter_Cases_heading_text {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 902px) {
  .SupportCenter_heading_text {
    color: #ff8800;
    font-size: 30px !important;
    font-weight: Bold !important;
  }
}

@media only screen and (max-width: 866px) {
    .cursor_pointer {
        position: relative;
        left: 0%;
      }
}

@media only screen and (max-width: 814px) {
  .SupportCenter_Cases_heading_text {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 768px) {
  .allcases_table_title { 
    width: 100px !important
  }
  .create_case_data {
    justify-content: space-evenly !important;
  }
  .create_case_search {
    justify-content: space-evenly !important;
  }
}