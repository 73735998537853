.highcharts-button-symbol {
  width: auto;
}
.App {
  font-family: sans-serif;
  text-align: center;
  background: red;
}
/* .MuiPaper-root{
    background-color: #0000;
} */

.highcharts-title {
  display: none;
}
.dropdown {
  z-index: 2 !important;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #0000;
}

.dropdown li {
  z-index: 2 !important;
  position: relative;
}

.dropdown li a {
  z-index: 2 !important;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  display: block;
  padding: 10px;
}

.dropdown li ul {
  position: absolute;
  z-index: 2 !important;
  top: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  line-height: normal;
  background-color: #333;
}

.dropdown li ul li a {
  z-index: 2 !important;
  text-align: left;
  color: #cccccc;
  font-size: 14px;
  padding: 10px;
  display: block;
  white-space: nowrap;
}

.dropdown li ul li a:hover {
  z-index: 2 !important;
  background-color: #0abf53;
  color: #ffffff;
}

.dropdown li ul li ul {
  left: 100%;
  top: 0;
}
.dropdown
  .date_picker_li
  .date_picker_sub_ul
  .date_picker_sub_li
  .date_picker_sub_li_li {
  z-index: 2 !important;
  left: -135% !important;
  top: 0;
}

.ul li:hover > .a {
  z-index: 2 !important;
  background-color: #0abf53;
  color: #ffffff !important;
}

.ul li:hover > ul {
  z-index: 2 !important;
  display: block;
}

.date_picker_ul {
  right: 0;
}
.no_data_text_box {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 30px !important;
  color: #fff !important;
  opacity: 0.4 !important;
  font-weight: bold !important;
}
@media only screen and (max-width: 850px) {
  .no_data_text_box {
    font-size:12px !important;
  }
}


/* IAMAnalysis------ */

.preview_model_back_col_title {
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  width: 70%;
  display: flex;
  align-items: center;
}
.background_color_design_box {
  padding: 5px 10px;
  border: 1px solid #fff;
  width: 70px;
  height: 33px;
  border-radius: 2px;
  cursor: pointer;
}
.color_inside_box {
  width: 50px;
  height: 20px;
  border-radius: 2px;
}
.preview_model_download_icon {
  margin-left: 16px;
  height: 33px;
  cursor: pointer;
}
.prev_model_download {
  color: #fff
}
@media only screen and (max-width: 768px) {
  .preview_model_back_col_title {
    font-size:11px !important;
    width: 70% !important;
  }
  .background_color_design_box {
    width: 55px !important; 
  }
  .color_inside_box {
    width: 34px !important;
  }
  .prev_model_download {
    font-size: 13px !important;
    height: 30px !important;
    margin-top: 7px !important;
  }
  .preview_model_download_icon {
    margin-left: 0px !important;
  }
}

.custom_toast{
  display: flex;
  background: #000;
  cursor: pointer;
  /* padding: 0px 20px 0px 0px; */
  /* border-radius: 8px; */
}
.custom_toast_massage {
  display: flex;
  margin-right: 10px;
  color:#fff;
  align-items: center;
}
.reload_btn{
  /* background-color: #38A1B8; */
  width: max-content;
  padding: 5px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
  color: #4594BB;
}

.replay_icon {
  display: flex;
  align-items: center;
  background: rgb(92, 196, 206);
  padding: 6px 10px;
}
.custom_toast_massage_part {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}