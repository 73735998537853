.signup__gridItem {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signup__gridContentItem {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.signup__gridContentBox {
  width: 50%;
}

.signup__label {
  font-size: 14px;
  color: #0fbcf9;
  margin-top: 20px;
  display: block;
  padding: 10px;
}
.signup__d-flex {
  display: flex;
}

.signup__input {
  border: 2px solid #bce0fd;
  border-radius: 50px;
  width: 360px;
  padding: 15px 20px;
  /* height: 50px; */
  background: #f1f9ff 0% 0% no-repeat padding-box;
  /* padding-left: 20px; */
  /* padding-bottom: 5px; */
}
.signup__input:focus {
  outline: none;
}
.signup__input::placeholder {
  padding-bottom: 10px;
  font-size: 14px;
  color: #000;
  vertical-align: sub;
}

.signup__label {
  font-size: 14px;
  color: #0fbcf9;
  margin-top: 20px;
  display: block;
  padding: 10px;
}

.signup__error_msgBody {
  position: relative;
  background-color: #808e9b;
  width: 188px;
  /* height: 50px; */
  left: 20px;
  border-radius: 10px;
}
.signup__error {
  display: flex;
  align-items: flex-end;
  position: 'absolute';
  right: 0;
  margin-top: 65px;
}
.signup__error_text {
  font-size: 14px;
  text-align: center;
  align-content: center;
  padding: 12px 0;
  /* margin-bottom: 0; */
}

.signup__alignItems {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 350px;
}
.signup__width350 {
  width: 350px;
}
.signup__alignCheckbox {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.signup__round {
  position: relative;
}
.signup__round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
}
.signup__round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 7px;
  left: 8px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}
.signup__round input[type='checkbox'] {
  visibility: hidden;
}
.signup__round input[type='checkbox']:checked + label {
  background-color: #0fbcf9;
  border-color: #0fbcf9;
}
.signup__round input[type='checkbox']:checked + label:after {
  opacity: 1;
}
.signup__cheackbox_text {
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  margin-left: 30px;
  margin-top: 10px;
}
.signup__forgotlink {
  color: #b4b4b4;
  font-size: 14px;
}
.signup__margin30 {
  margin-top: 20px;
}
