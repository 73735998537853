.DahboardThreeBody__round {
  position: relative;
  width: 20px;
  height: 20px;
}

.DahboardThreeBody__round input[type='checkbox'] {
  visibility: hidden;
}
/* .DahboardThreeBody__round input[type='checkbox']:checked + label {
    background-color: #0fbcf9;
    border-color: #0fbcf9;
  } */
.DahboardThreeBody__round input[type='checkbox']:checked + label {
  background-color: transparent;
  border-color: #ccc;
}
.DahboardThreeBody__round input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.DahboardThreeBody__round label {
  background-color: transparent;
  border: 2px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}
.DahboardThreeBody__round label:after {
  border: 2px solid rgb(36, 207, 36);
  border-top: none;
  border-right: none;
  content: '';
  height: 4px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 2px;
  transform: rotate(-45deg);
  width: 8px;
}
