/* table {
  border-collapse: inherit;
  border-spacing: 0 10px;
} */
/* tr {
  background-color: '#272E42';
} */
/* th {
  background-color: green;
  color: white;
  flex-grow: 1;
  border: 1px solid;
} */
th,
td {
  /* text-align: center; */
  /* border: 1px solid black; */
  padding: 10px;
  /* flex-grow: 1; */
}

th:first-child {
  border-left-style: rgba(0, 0, 0, 0.3);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
th:last-child {
  border-right-style: rgba(0, 0, 0, 0.3);
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
td:first-child {
  border-left-style: rgba(0, 0, 0, 0.3);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
td:last-child {
  border-right-style: rgba(0, 0, 0, 0.3);
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

tr:hover {
  background-color: #272e42;
}
tr {
  margin-top: 20px;
}
.dashboardOne__round {
  position: relative;
  width: 30px;
  height: 30px;
}

.dashboardOne__round input[type="checkbox"] {
  visibility: hidden;
}
/* .dashboardOne__round input[type='checkbox']:checked + label {
  background-color: #0fbcf9;
  border-color: #0fbcf9;
} */
.dashboardOne__round input[type="checkbox"]:checked + label {
  background-color: transparent;
  border-color: #ccc;
}
.dashboardOne__round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.dashboardOne__round label {
  background-color: transparent;
  border: 2px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
}
.dashboardOne__round label:after {
  border: 2px solid rgb(36, 207, 36);
  border-top: none;
  border-right: none;
  content: "";
  height: 7px;
  left: 8px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}
.Reset-Child-password-Model-box {
  margin: auto;
  background: #010a26 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 30px;
  opacity: 1;
  padding: 20px;
}
.user_date_table {
  color: #fffa;
}
.user_date_table:hover td {
  color: #81f5ff !important;
}
.user_date_table > td:last-child {
  display: block !important;
}

.user_date_table_select_row_color {
  color: #81f5ff !important;
  background-color: #272e42;
}
.user_date_table_select_row_color > td:last-child {
  display: none !important;
}

.user_date_table:hover > td:last-child {
  display: none !important;
}
.user_date_table > td:nth-last-child(2) {
  display: none !important;
}

.user_date_table:hover > td:nth-last-child(2) {
  display: block !important;
}
.user_date_table_text {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-height: 62px !important;
}
.text-white {
  color: #fff !important;
}
