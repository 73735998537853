.discover-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #03072a;
}

.discover-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  justify-content: center;

  .messages-container {
    width: 70%;

    .default-discover-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 40px;
      padding: 50px;

      .question-card {
        display: flex;
        gap: 20px;

        &>div {
          flex: 1;
          padding: 30px;
          border: 1px solid white;
          border-radius: 10px;
          color: white;

          &:hover {
            opacity: 0.8;
            cursor: pointer;
          }
        }
      }

      &>img {
        width: 240px;
        height: auto;
      }
    }

    .container-user {
      align-items: baseline;
      flex-direction: row-reverse !important;
    }

    .message-container {
      margin-bottom: 10px;
      padding: 8px;
      border-radius: 8px;
      word-wrap: break-word;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: baseline;

      .message-owner {
        display: flex;
        gap: 10px;
        font-weight: bold;
        font-size: 16px;
        color: white;
        align-items: center;

        &>img {
          width: 32px;
        }
      }

      .message {
        padding: 10px;
        border-radius: 10px;
        width: auto;
        max-width: 70%;
        position: relative;
        min-width: 100px;

        &.user {
          background-color: #2b2253;
          color: white;
          align-self: flex-end;

          &>p {
            margin: 0;
          }
        }

        .message-timestamp {
          position: absolute;
          bottom: 4px;
          right: 8px;
          font-size: 12px;
          text-align: end;
        }

        &.bot {
          background-color: #e5f4e3;
          align-self: flex-start;
          padding-bottom: 10px;
        }
      }
    }
  }
}


.discover-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;

  & button {
    height: fit-content;
  }

  .discover-input {
    padding: 8px 5px 8px 24px;
    background-color: #fff;
    border: 2px solid #b9b9b9;
    margin: 10px;
    min-width: 70%;
    border-radius: 50px;
    display: flex;
    align-items: center;

    .loader-icon {
      padding: 4px;
      // margin-right: 16px;
      display: flex;

      &>span {
        width: 20px !important;
        height: 20px !important;
      }

      &>span>svg {
        color: rgb(107, 107, 107) !important;
      }
    }

    .chatboat-textarea {
      font-size: 16px;
      width: 100%;
      max-height: 100px;
      overflow-y: auto;
      resize: none;
      min-height: 24px;
      border: 0px;
      background-color: transparent;
      outline: none;
    }
  }

  button>svg {
    color: black !important;
  }
}



.markdown-content {
  height: 100%;

  &>p {
    margin: 0 !important;
  }

  // Overall styles
  font-family: Arial,
  sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #333;

  // Style headings
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0.5em 0;
    font-weight: bold;
    color: #333;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.17em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 0.83em;
  }

  h6 {
    font-size: 0.67em;
  }

  p {
    margin: 1em 0;
  }

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ul,
  ol {
    margin: 1em 0;
    padding-left: 2em;
  }

  li {
    margin-bottom: 0.5em;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 1em;

    th,
    td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
    }

    th {
      background-color: #f2f2f2;
    }

    tr:hover {
      background-color: #f5f5f5;
    }
  }

  pre {
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
    overflow-x: auto;
    font-family: 'Courier New', Courier, monospace; // Change font for pre tag
    line-height: 1.4;
    margin-bottom: 1em;
  }

  blockquote {
    margin: 0;
    padding: 0 15px;
    border-left: 4px solid #007bff;
    color: #777;
  }

  img {
    max-width: 100%;
  }
}

.left-arrow-chat {
  position: absolute;
  top: 2px;
  left: -7px;
  border-width: 8px 11px 8px 0;
  border-color: transparent #e5f4e3 transparent transparent;
}

.right-arrow-chat {
  position: absolute;
  top: 2px;
  right: -7px;
  border-width: 8px 0 8px 11px;
  border-color: transparent transparent transparent #2b2253;
}

.loader-answer {
  height: calc(100% - 50px);
  transition: 0.5s;
  display: flex;
  width: 70%;
  gap: 10px;

  &>img {
    width: 32px;
    height: 32px;
  }

  &>div {
    flex: 1;

    &>span {
      background-color: #e5f4e3;
    }
  }
}

.response-height {
  height: calc(100% - 50px);
}