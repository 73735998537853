.MuiTableSortLabel-iconDirectionAsc{
    font-size: 50px !important;
    color: #fff !important;

}
.MuiTableSortLabel-iconDirectionDesc{
    font-size: 50px !important;
    color: #fff !important;

}
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
    fill:#fff !important;
    font-size: 30px !important;
}
.css-bpeome-MuiSvgIcon-root-MuiSelect-icon{
    fill:#fff !important;
    font-size: 30px !important;
}
.selected_user_table_titles {
    font-size: 14px !important;
    color: white !important;
    font-weight: 600 !important;
    border: 0px !important;
}
.policy_recommendation_main_container {
    margin-top: 40px !important;
    background-color: rgb(0,0,0,0.3);
    padding: 10px 25px 25px 20px !important;
    border-radius: 20px;
}
@media only screen and (max-width: 1174px) {  
    .select_user_policy_table {
        min-width: 480px !important;
    }
  }
  @media only screen and (max-width: 1170px) {  
    .select_user_policy_table {
        min-width: 450px !important;
    }
  }
  @media only screen and (max-width: 1078px) {  
    .select_user_policy_table {
        min-width: 390px !important;
    }
  }
@media only screen and (max-width: 1024px) {  
    .sectionone_left_filter {
        width: 69% !important;
    }
    .select_user_policy_table {
        min-width: 380px !important;
    }
  }
  @media only screen and (max-width: 1000px) {  
    .policy_recommendation_main_container {
        /* overflow-x: scroll; */
        width: 320px !important;
    }
    .select_user_policy_table {
        min-width: 280px !important;
    }
  }
@media only screen and (max-width: 1004px) {  
    .selected_user_table_titles {
        font-size: 11px !important;
    }
  }
@media only screen and (max-width: 982px) {  
    .selected_user_table_titles {
        font-size: 10px !important;
    }
    .select_user_policy_table {
        min-width: 280px !important;
    }
  } 
  @media only screen and (max-width: 942px) {  
    .policy_recommendation_main_container {
        width: 288px !important;
    }
    .select_user_policy_table {
        min-width: 250px !important;
    }
    
  }
  @media only screen and (max-width: 906px) {  
    .policy_recommendation_main_container {
        width: 254px !important;
    }
    .select_user_policy_table {
        overflow-x: scroll !important;
        min-width: 210px !important;  
    }
  }
  @media only screen and (max-width: 873px) {  
    .policy_recommendation_main_container {
        width: 220px !important;
    }
    .select_user_policy_table {
        overflow-x: scroll !important;
        min-width: 180px !important;  
    }
  }
  @media only screen and (max-width: 837px) {  
    .policy_recommendation_main_container {
        width: 80% !important;
        overflow-x: auto !important;
    }
    .acc_ana_right_user_search_filter {
        width: 90% !important;
    }
    .sectionone_left_filter {
        width: 55% !important;
    }
  }
@media only screen and (max-width: 768px) {  
    .ana_secure_analysis_title {
        font-size: 23px !important;
    }
    .sec_analysis_state_item {
        font-size: 12px !important;
    }
    .sec_analysis_state_value {
        font-size: 12px !important;
    }
    .acc_ana_public_acces_data {
        font-size: 18px !important;
    }
    .public_table_font {
        font-size: 2px !important;
    }

    .acc_ana_iam_filter_title {
        font-size: 23px !important;
    }
    .ana_filter_title,.ana_User_title {
        font-size: 19px !important; 
    }
    .aca_ana_user_refresh_img {
        width: 17px !important;
        height: 17px !important;
        margin-left: 10px !important;
    }
    .acc_ana_filter_item_data_label {
        font-size: 14px !important;
    }
    .acc_ana_checkbox_value_label {
        font-size: 12px !important;
    }
    .acc_ana_user_groups_label {
        font-size: 12px !important;
    }
    .acc_ana_user_count {
        font-size: 12px !important;
    }
    .acc_ana_user_userdata_count {
        font-size: 12px !important;
    }
    .acc_ana_user_detail_label {
        font-size: 12px !important;
    }
    .sectionone_left_filter {
        width: 53% !important;
    }
    .acc_ana_right_user_search_filter {
        margin-left: -10px !important;
        margin-right: 10px !important;
    }
  }
