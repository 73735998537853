.select-visible-columns-part {
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.text-white {
  color: #fff;
}

.Wrap-lines-text-description {
  color: #ffffffa6;
}
.Wrap-lines-text-box {
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.margin-top-20 {
  margin-top: 20px;
}
