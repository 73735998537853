.case-details-box {
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid #707070;
  border-radius: 30px;
  padding: 52px 0;
  margin: 60px 0;
}
.case-details-sub-box {
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid #707070;
  border-radius: 30px;
  padding: 10px 0;
  margin: 25px 40px;
}
.case-details-header-title {
  font-size: 24px;
  font-weight: bold;
  padding: 24px 40px;
  border-bottom: 1px solid #707070;
  width: 100%;
}
.padding-left-right-15 {
  padding: 0 80px;
}
.case-details-sub-text-title {
  color: #ffffff;
  font-size: 18px;
}

.opacity-05 {
  opacity: 0.5;
}

.case-details-text-grid {
  margin: 15px 0px;
}

.case-details-content-box {
  width: auto !important;
  margin: 25px 40px;
}
.padding-top-20 {
  padding-top: 20px;
}
.case-details-content-header {
  /* border-right: 1px solid #494b50; */
  display: flex;
  align-items: flex-start;
  justify-content: start;
  font-size: 24px;
  font-weight: bold;
  width: 100%;
  padding-left: 30px;
}
.case-details-ratings-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #008cf2;
  width: max-content;
  padding: 9px 30px;
}
.case-details-content-sub-header {
  color: rgba(225, 225, 225, 0.8);
  opacity: 1;
  font-size: 16px;
  border-right: 1px solid #494b50;
  padding: 0 25px;
}
.case-details-content-description {
  text-align: left;
  letter-spacing: 0px;
  color: rgba(225, 225, 225, 0.8);
  font-size: 16px;
  padding: 0 0px 25px 50px;
}
.padding-top-60 {
  padding: 60px;
}
.reply-box {
  border-bottom: 1px solid #494b50;
  opacity: 1;
  width: 100%;
}
.reply-font-size {
  font-size: 18px;
}
.reply-details {
  padding: 26px 76px;
  width: 100%;
}
.reply-link-color {
  color: #008cf2;
}
.reply-text-title {
  padding-bottom: 8px;
}
.reply-here-text {
  padding-bottom: 2px;
  cursor: pointer;
}
.reply-export-icon {
  width: 12px;
  margin-left: 5px;
}
.reply-textarea {
  color: white;
  background-color: rgb(39, 46, 66);
  border: 1px solid #008cf2;
  width: 100%;
}
.reply-attachment-file {
  padding: 8px 0px 8px 0px;
}
.reply-upload-button {
  border: 1px solid #008cf2;
  padding: 8px 35px;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}
.reply-upload-box {
  width: max-content;
  padding: 0px 0px 8px 0px;
}
.reply-up-to-attachment {
  padding-bottom: 18px;
}
.reply-contact-info {
  padding-bottom: 10px;
}
.reply-info-text {
  margin-left: -55px;
  padding-top: 5px;
  float: left;
}
.reply-contactct-box {
  border: 1px solid #008cf2;
  width: 350px;
  padding: 11px 26px;
  background-color: rgba(0, 140, 242, 0.2);
  margin-bottom: 20px;
}
.reply-web-text {
  font-size: 18px;
}
.reply-radio-status {
  float: right;
  margin-top: -1px;
}
.reply-cancel-button {
  border: 1px solid #2975cc;
  border-radius: 20px;
  padding: 0px 15px;
  width: fit-content;
  padding: 6px 32px;
  margin-right: 10px;
  cursor: pointer;
}
.reply-submit-button {
  margin-top: 10px;
  background-color: #328cf2;
}
@media only screen and (max-width: 1116px) {
  .case-details-content-sub-header {
    max-width: 37.666667% !important;
  }
  .submit_data_resp {
    max-width: 62.333333% !important;
  }
}
@media only screen and (max-width: 866px) {
  .case_reopen_case_info {
    justify-content: space-evenly !important;
    margin-left: -40px !important;
  }
}
