.select_account_id_model {
    background: #010A26;
    padding: 40px;
    width: 20%;
    border-radius: 0px;
    max-height: 90%;
    overflow: auto;
}
@media only screen and (max-width: 1226px) {
    .select_account_id_model {
        width: 30% !important;
    }
  }
  @media only screen and (max-width: 1032px) {
    .select_account_id_model {
        width: 35% !important;
    }
  }
  @media only screen and (max-width: 750px) {
    .select_account_id_model {
        width: 41% !important;
    }
  }
