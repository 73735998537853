.Profile__background {
    background: url('../../../assets/img/19.png') 0% 0% no-repeat padding-box;
    opacity: 1;
    background-color: #020922;
    background-size: cover;
    width: 100%;
    background-position: center;
    min-height: 100vh;
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 29px 99px 0px 99px;
  }

  .your-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    font-size: 1.5em;
    font-weight: 100;
    color:white;
    user-select: none;
}

.B_H_btnhover:hover{
  color: #008CF2;
}

@media only screen and (max-width: 768px) {
  .address_box {
    width: 450px;
  }
  .profile-img-box {
    flex-wrap: wrap;
    padding: 44px 50px !important;;
  }
  .tim_res_box {
    width: 50% !important;
    margin-top: 31px !important;
  }
  .high_timeline_resp {
    width: 50% !important;
    margin-top: 50% !;
  }
}

@media only screen and (max-width: 1124px) {
  .box_responsive_fontsize {
      font-size: 10px !important;
  }
 .box_responsive_fontsize2 {
  font-size: 12px !important;
  width: 120px;
}
.plan_detail_content {
  margin-left: -35px;
  margin-right: -55px;
}
.contact_img {
  width: 50px !important;
  height:50px !important;
}
.pr_bil_delete {
  font-size: 68% !important;
}
.delete_edit_section {
  font-size: 68% !important;
}

}

@media only screen and (max-width: 1356px) {
  .box_responsive_fontsize {
      font-size: 10px !important;
  }
 .box_responsive_fontsize2 {
  font-size: 12px !important;
  width: 120px;
}
.plan_detail_content {
  margin-left: -35px;
  margin-right: -55px;
}
.contact_img {
  width: 50px !important;
  height:50px !important;
}
.pr_bil_delete {
  font-size: 68% !important;
  margin-left: 20px !important;
  margin-bottom: 10px;
}
.delete_edit_section {
  font-size:  74% !important;
  margin-left: 20px !important;
}
.added_delete_resp {
  font-size: 68% !important;
  margin-left: 20px !important;
  margin-bottom: 10px !important;
}
.added_edit_resp {
  font-size:  74% !important;
  margin-left: 20px !important;
}
.rsp_add_card {
  font-size: 80% !important;
}
.add_addrress_resp {
  font-size: 80% !important;
}

}

@media only screen and (max-width: 1292px) {
  .box_responsive_fontsize {
      font-size: 10px !important;
  }
 .box_responsive_fontsize2 {
  font-size: 12px !important;
  width: 120px;
}
.plan_detail_content {
  margin-left: -35px;
  margin-right: -55px;
}
.contact_img {
  width: 50px !important;
  height:50px !important;
}
.pr_bil_delete {
  font-size: 68% !important;
  margin-left: 20px !important;
  margin-bottom: 10px;
  margin-top: 50px;
}
.delete_edit_section {
  font-size:  74% !important;
  margin-left: 20px !important;
}
.added_delete_resp {
  font-size: 68% !important;
  margin-left: 20px !important;
  margin-bottom: 10px !important;
}
.added_edit_resp {
  font-size:  74% !important;
  margin-left: 20px !important;
}
.rsp_add_card {
  font-size: 80% !important;
  margin-top: 130px !important;
  margin-left: -180px !important;
}
.add_addrress_resp {
  font-size: 80% !important;
  margin-top: 170px !important;
    margin-left: -180px !important;
}

}

@media only screen and (max-width: 1486px) {
 .pr_bill_resp {
    margin: 7px !important;
 }
 .delete_edit_section {
  margin-top: 10px !important;
}
}

@media only screen and (max-width: 1296px) {
  .visa_img_resp {
    width: 43px !important;
    height: 60px !important;
    /* margin-left: -10px !important; */
  }
  .visa_text_resp {
    font-size: 12px !important;
  }
  .delete_edit_section {
    margin-top: 10px !important;
  }
  .profil_bill_resp_img {
    width: 100px !important;
    height: 100px !important;
  }
  .resp_profile_name_bill {
    font-size: 19px !important;
  }
  .time_resp {
    margin-left: 0px !important;
  }
}
@media only screen and (max-width: 950px) {
  .rsp_add_card {
    font-size: 80% !important;
    margin-top: 180px !important;
    margin-left: -180px !important;
  }
  .add_addrress_resp {
    font-size: 80% !important;
    margin-top: 170px !important;
      margin-left: -180px !important;
  }
}

@media only screen and (max-width: 950px) {
  .time_resp {
    margin-left: 21px !important;
    /* width: 100% !important; */
  }
}
@media only screen and (max-width: 758px) {
  .time_resp {
    margin-left: 12px !important;
    /* width: 100% !important; */
  }
}

@media only screen and (max-width: 752px) {
  .time_label_resp {
    font-size: 18px !important;
  }
  .day_rem_resp {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 898px) {
  .rsp_add_card {
    font-size: 80% !important;
    margin-top: 180px !important;
    margin-left: 147px !important;
  }
  .add_addrress_resp {
    font-size: 80% !important;
    margin-top: 170px !important;
    margin-left: 121px !important;
  }
  .added_edit_resp {
    margin-top: -8px !important;
  }
  .delete_edit_section {
    margin: 8px !important;
  }
}

@media only screen and (max-width: 876px) {
  
  .my_card_box_resp {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1048px) {
  
  .my_card_box_resp {
    width:110% !important;
  }
}
@media only screen and (max-width: 768px) {
  
  .my_card_box_resp {
    width: 110% !important;
  }
  .billling_resp {
    padding: 20px !important;
  }
}



