.react-daterange-picker__clear-button {
  display: none !important;
}

.react-daterange-picker__calendar-button {
  display: none !important;
}

.react-daterange-picker__wrapper {
  border: 0px !important;
}

.react-daterange-picker__inputGroup__day {
  color: white !important;
}

.react-daterange-picker__inputGroup__month {
  color: white !important;
}

.react-daterange-picker__inputGroup__year {
  color: white !important;
}

.react-daterange-picker__range-divider {
  padding: 0px 6px 0px 0px !important;
}
/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: #fff !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #fff !important;
} */
/* .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
  border-width: 2px !important;
} */
/* .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #fff !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  color: #fff !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: #fff !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}
.MuiOutlinedInput-notchedOutline .css-igs3ac {
  border-color: #fff !important;
}
.css-1ftyaf0 {
  color: #fff;
} */
