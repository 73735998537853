* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  /* background-color: black; */
}
.dotImg{
  width: 2x !important;
  background-color: yellow !important;
}