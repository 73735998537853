.headerLeftPart {
  width: "60%";
}

.img {
  width: 36px;
  height: 36px;
}

.HeaderTitle {
  font-size: "14px";
}

.header-item-font-size {
  font-size: 14px;
}

.header-specs {
  width: 10%;
}

.header-logo {
  width: 100%;
  height: 100%;
}

.header-time-section {
  margin-right: 20px;
  min-width: 100px;
  width: auto;
}

.header-profile-details-section {
  margin-right: 20px;
}

@media screen and (max-width: 1480px) {
  .header-item-font-size {
    font-size: 13px;
  }

  .img {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 1427px) {
  .header-item-font-size {
    font-size: 8px;
  }

  .img {
    width: 25px;
    height: 25px;
  }

  .header-specs {
    width: 0%;
  }

  .header-logo {
    width: 100%;
    height: 100%;
  }

  .header-profile-details-section {
    margin-right: 0px;
  }

  .header-time-section {
    margin-right: 0px;
    width: 75px;
  }

}

@media screen and (max-width: 1024px) {
  .header-item-font-size {
    font-size: 8px;
  }

  .header-specs {
    width: 0%;
  }

  .header-logo {
    width: 100%;
    height: 100%;
  }

  .img {
    width: 20px;
    height: 20px;
  }

  .header-profile-details-section {
    margin-right: 0px;
  }

  .header-time-section {
    margin-right: 0px;
    width: 75px;
  }
}

@media (min-width: 1020px) {
  .box {
    background: blue;
    color: aquamarine;
  }

  .HeaderTitle {
    font-size: 14px;
  }

}

@media (min-width: 1440px) {
  .box {
    background: rgb(0, 192, 16);
    color: aquamarine;
  }
}

.nav-item:hover > img {
  filter: brightness(0) invert(1);  
}

.nav-item:hover span{
  color: white;
}

.close-icon{
  position: absolute !important;
  right: 0;
  top: 0;
  & > svg{
    color: black !important;
  }
}

.badge-icon{
  position: absolute !important;
  // right: 0;
  top: -22px;
  & > svg{
    color: #46faff !important;
  }
}

.chart-type{
  & > div{
    border: 0 !important;
  }
  
}