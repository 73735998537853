.custome_stepper{
    display: flex;
    margin: auto;
}

.cloud_step_circle{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cloud_step_circle_line_show{
    width: 298px;
}

.cloud_step_circle_without_line_show{
    width: 200px;
    margin-left: 15px;
}
.cloud_steps_Line{
    height: 5px;
    width: 250px;
    position: absolute;
    left: 50px;
    margin: 0px 0px;
    border-radius: 4px;
}


@media only screen and (max-width: 1193px) {
    .cloud_step_circle_line_show {
        width: 230px;
    }
        .cloud_step_circle_without_line_show {
            width: 230px;
        }
    .cloud_steps_Line {
        width: 200px;
    }
}@media only screen and (max-width: 1024px) {
    .cloud_step_circle_line_show {
        width: 215px;
    }
        .cloud_step_circle_without_line_show {
            width: 215px;
        }
}
@media only screen and (max-width:933px) {
    
    .cloud_steps_title {
        font-size: 12px !important;
    }
    .cloud_step_circle_line_show{
        width: 165px;
    }
        .cloud_step_circle_without_line_show {
            width: 165px;
        }
    .cloud_steps_Line{
        width: 150px;
    }
}
@media only screen and (max-width:827px) {
    .cloud_steps_title{
        font-size: 10px !important;
    }
    .cloud_step_circle_line_show{
        width: 140px;
    }
    
    .cloud_step_circle_without_line_show{width: 150px;
        margin-left:0px;
    }
    .cloud_steps_Line{
        width: 105px;
    }
}


@media only screen and (max-width:765px) {
    .custome_stepper {
            flex-wrap: wrap;
        }
}
