.background{
        background: url('../../assets/img/19.png') 0% 0% no-repeat padding-box;
        opacity: 1;
        background-color: #020922;
        background-size: cover;
        width: 100%;
        background-position: center;
        min-height: 100vh;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
}

.chart{
    background-color:"black";
    width:"800px";
    height:"600px";
    margin-top: -200px;
}
.time{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}
.secNav{
    width:100%;
    height:65px;
    background: #010515 0% 0% no-repeat padding-box;
    opacity: 1;
}
.main{
    display: flex;
    flex-direction: row !important;
    /* flex-direction: row; */
    /* justify-content: space-between;
    align-content: space-between; */
    margin-top: 20px;
    margin-bottom: 60px;
    width: 100%;
}
.left{
    
    width: 530px;
    height: 420px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    margin-left: 35px;
    width:40%;
}
.lefttop{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.lefttl{
       padding:20px;
}
.lefttr{
    padding:20px !important;
    display: flex;
    flex-direction: row;
}
.chart{
    width: 350px;
    height:330px;
    margin-left:180px;
    margin-top:-5px;
    background-color: #FFFFFF;
}
.charti{
   padding:20px;
}
.right{
   margin-left: 2%;
   margin-right:25px;
    width: 60%;
   height: 420px;
   background: rgba(0, 0, 0, 0.3);
   border-radius: 20px;
   position: relative;

}
.head{
    text-align: left;
    letter-spacing: 0px;
    color: #FF9900;
    position: absolute;
    padding-top: 30px ;
    padding-left: 50px;
    font-size: 40px !important;
    font-weight: bolder;
}
.thre{
    display: flex;
    flex-direction: row;
    margin-top: 130px;
    padding-left: 50px;
}
.center{
    margin-left: 50px;
}
.ri{
    margin-left: 50px;
}
.footer{
    margin-left: 40px;
    width:95%;
    height:200px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    
}
.footerb{
    display: flex;
    flex-direction: row;
}
.footerbox{
    margin-top: 45px;
    margin-left:20px;
    width:265px;
    height:127px;
    
}
.footerse{
    margin-top: 10px;
    display:flex;
    flex-direction: row;
    color: rgb(255, 255, 255);
    justify-content: space-between;
}
.footert{
    margin-top: 10px;
    width: 268px;
    height: 36px;
    background:rgb(255, 255, 255,0.2);
    color: #FFFFFF;
    border: 1px solid #D7DBEC;
    border-radius: 4px;
    opacity: 0.2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bolder;
}
/* -----------------chart---------------------------- */
.donutchart-legend-item-rect{
    display: none !important;
}
.donutchart-legend-item-label{
    display: none !important;
}
.donutchart-innertext-label{
    display: none !important;
}
.donutchart-innertext-value{
    display: none !important;
}
/* ---------------------chart end----------------------- */