.verifyNumber__background {
  background: url('../../assets/img/19.png') 0% 0% no-repeat padding-box;
  opacity: 1;
  background-color: #020922;
  background-size: cover;
  width: 100%;
  background-position: center;
  height: 100vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.verifyNumber__header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.verifyNumber__linkBox {
  /* background-color: red; */
  margin: 20px;
}
.verifyNumber__link {
  text-decoration: none;
  color: white;
  font-weight: lighter;
}
.verifyNumber__linkAccount {
  text-decoration: none;
  color: white;
  font-weight: 600;
}
.verifyNumber__gridItem {
  display: flex;
  justify-content: center;
  align-items: center;
}
.verifyNumber__gridContentItem {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.verifyNumber__alignItems {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 350px;
}
.verifyNumber__width350 {
  width: 350px;
}
.verifyNumber__margin50 {
  margin-top: 50px;
}

.verifyNumber__alignCheckbox {
  display: flex;
  align-items: center;
  padding-top: 20px;
}
.verifyNumber__footerLayout {
  width: 80%;
  align-self: center;
  height: 10%;
}

.verifyNumber__gridContentBox {
  width: 50%;
}
.verifyNumber__label {
  font-size: 14px;
  color: #0fbcf9;
  margin-top: 20px;
  display: block;
  padding: 10px;
}
.verifyNumber__input {
  border: 2px solid #bce0fd;
  border-radius: 50px;
  width: 360px;
  height: 50px;
  background: #f1f9ff 0% 0% no-repeat padding-box;
  padding-left: 20px;
  padding-bottom: 5px;
}
.verifyNumber__input:focus {
  outline: none;
}
.verifyNumber__input::placeholder {
  padding-bottom: 10px;
  font-size: 14px;
  color: #000;
  vertical-align: sub;
}

.verifyNumber__round {
  position: relative;
}
.verifyNumber__round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
}
.verifyNumber__round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 7px;
  left: 8px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}
.verifyNumber__round input[type='checkbox'] {
  visibility: hidden;
}
.verifyNumber__round input[type='checkbox']:checked + label {
  background-color: #0fbcf9;
  border-color: #0fbcf9;
}
.verifyNumber__round input[type='checkbox']:checked + label:after {
  opacity: 1;
}
.verifyNumber__cheackbox_text {
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  margin-left: 30px;
  margin-top: 10px;
}
.verifyNumber__forgotlink {
  color: #b4b4b4;
  font-size: 14px;
}
.verifyNumber__footerLink {
  text-decoration: none;
  color: #fff;
  padding-right: 30px;
  padding-left: 30px;
}

.verifyNumber__footerBorderLink {
  border-right: 2px solid white;
}
.verifyNumber__error_msgBody {
  position: relative;
  background-color: #808e9b;
  width: 188px;
  height: 50px;
  left: 20px;
  border-radius: 10px;
}
.verifyNumber__error {
  display: flex;
  align-items: flex-end;
  position: 'absolute';
  right: 0;
  margin-top: 55px;
}
.verifyNumber__error_text {
  font-size: 14px;
  text-align: center;
  align-content: center;
  padding: 12px 0;
  margin-bottom: 0;
}
.verifyNumber__resentSms {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}
.verifyNumber__resentLink {
  text-decoration: none;
  color: #008fff;
  font-weight: 700;
  font-size: 20px;
}
/* .verifyNumber */
