.setup-source-next-btn {
    margin-left: 20px !important;
    border-radius: 20px !important;
    padding: 5px 25px !important;
    color: white;
    width: 180px !important;
    margin: 10px !important;
}
.setup-source-back-btn {
    margin-left: 20px !important;
    border-radius: 20px !important;
    padding: 5px 25px !important;
    color: white;
    width: 180px !important;
    margin: 10px !important;
}

@media screen and (max-width:804px) {
    .role_based_access_label,.key_access_label {
       font-size: 12px !important;
     }
    .or_manullay_create ,.set_source_account_id{
        font-size: 11px !important;
     }
     .setup_source_external_id {
         font-size: 11px !important;
     }
     .setup-source-next-btn {
         width: 119px !important;
     }
     .setup-source-back-btn {
         width: 119px !important;
     }
   }