.EC2Status__background {
    background: url('../../../assets/img/19.png') 0% 0% no-repeat padding-box;
    opacity: 1;
    background-color: #020922;
    background-size: cover;
    width: 100%;
    background-position: center;
    min-height: 100vh;
    color: #fff;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
  }