.css-i4bv87-MuiSvgIcon-root{
    color: #fff !important;
}

.apexcharts-tooltip-title{
    color:  #000
}
.apexcharts-tooltip-series-group:last-child{
    color:  #000
}
@media screen and (max-width:1126px) {
    .date_label_resp {
      font-size: 10px !important;
      width: 170px !important;
      border-radius: 4px;
      margin-left: -31.5px !important;
    }
    .date_label_resp2 {
        font-size: 10px !important;
        margin-left: -31px !important;
        width: 172px !important;
    }
    .date_label_resp3 {
        font-size: 10px !important;
        margin-left: -27.5px !important;
        width: 185px !important;
    }
    .date_label_resp4 {
        font-size: 10px !important;
        margin-left: -20px;
        width: 175px !important;
    }
    .cal_img_resp {
        width: 18px !important;
        height: 15px !important
    }
    .date-resp1 {
        margin-left:-20px;
    }
    .date-resp2 {
        margin-left:-20px;
    }
    .date-resp3 {
        margin-left:-20px;
    }
    .date-resp4 {
        margin-left:-20px;
    }
    .img_but_resp {
        margin-left: -20px !important;
    }
}