.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    border: 1px solid #008CF2 !important;
}
.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input{
    padding-top:0px !important;
    padding-bottom:0px !important;
}


@media only screen and (max-width: 1124px) {
    .plan_card_font {
        font-size: 11px  !important;
    }
}
@media only screen and (max-width: 768px) {
    .plan_upgrade_text {
        font-size:10px !important
    }
    .bullet_point_resp {
        width: 6px !important;
        height: 6px !important;
    }
    .plan_detail_box {
        padding-right:5px !important;
    }
    .plan_upgrade_img {
        width: 30px;
        height:30px;
        margin-left: 40%;
        margin-top: 5px;
    }
}
