@tailwind base;
@tailwind components;
@tailwind utilities;

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper::-webkit-scrollbar {
  width: 0.3em;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper::-webkit-scrollbar-thumb {
  background-color: #add8e6;
  outline: 0px solid rgb(248, 0, 0);
  border-radius: 2;
}

.app__globalContainer {
  width: 100%;
  min-height: 100vh;
}
a {
  text-decoration: none !important;
  color: #fff;
}

.subHeaderTitle {
  color: #fff;
}

.subHeaderTitle:hover {
  color: rgb(129, 245, 255);
}
.subHeaderBorderBottomTitle {
  color: #fff;
}

.subHeaderBorderBottomTitle:hover {
  color: rgb(129, 245, 255);
  border-bottom: 2px solid #008cf2;
}

/* borderBottom: '2px solid #008CF2', */

.borderBottom:hover {
  color: rgb(129, 245, 255) !important;
  border-bottom: 1px solid rgb(129, 245, 255);
}

textarea{
  color: black;
}