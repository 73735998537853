.aws_region_select_model {
    background: #010A26;
    padding: 40px;
    width: 20%;
    border-radius: 0px;
    max-height: 90%;
    overflow: auto;
}
@media only screen and (max-width: 1162px) {
    .aws_region_select_model {
        width: 38% !important;
    }
  }