.css-6hp17o-MuiList-root-MuiMenu-list{
    padding:0px !important;
}

@media only screen and (max-width: 1124px) {
    .plan_box_card {
        width:130%;
       margin-left: -14%;
    }
    .plan_box_card1 {
        width:100%;
    }
  }

@media only screen and (max-width: 1124px) {
    .box_responsive_font {
        font-size: 10px !important;
    }
   .box_responsive_font2 {
    font-size: 12px !important;
}

}