.setting-preferences-model-container {
    background: #010A26; 
    padding: 20px; 
    width: 30%; 
    border-radius: 20px; 
    max-height: 90%; 
    overflow: auto;
}
@media only screen and (max-width: 1128px) {
    .setting-preferences-model-container {
        width: 40% !important; 
    }
  }
@media only screen and (max-width: 768px) {
    .setting-preferences-model-container {
        width: 53% !important; 
    }
  }