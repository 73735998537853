.photo{
    margin-top: 0px;
    padding: auto;
    margin-left: auto;
    margin-right: auto;
}
.homegrid{
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.css-12g7no8{
    background: rgb(0, 0, 0,0.3);
}
.photoname{
    color: #FFFFFF;
    font: normal normal bold 30px/40px Segoe UI;
    margin-top: 70px;
}
.photodis{   
    font : normal normal normal 30px/40px Segoe UI;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-top: 43px;
}
.photosubdis{
    margin-top: 35px;
    font: normal normal normal 22px/30px Segoe UI;
    color: #81F5FF;
    opacity: 1;
}
.ditels{
    background: rgba(0, 0, 0, 0.0.6) !important;
    padding-top: 25px;
}
.hedingdetels{
    text-align: left;
    font: normal normal bold 30px/40px Segoe UI;
    letter-spacing: 0px;
    color: #008CF2;
    opacity: 1;
    padding-left: 10%;
}
.textbox{
    text-align: left;
    width: 70%;
    height: 44px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 20px;
    font-size: 22px;
    padding-left: 20px;
    border: none !important;
}

@media screen and (max-width: 1452px) {
    .textbox {
      font-size: 14px;
    }
    .textbox_font {
        font-size: 14px !important;
    }
}
@media screen and (max-width: 1108px) {
    .textbox {
      font-size: 10px !important    ;
    }
    .textbox_font {
        font-size: 10px !important;
    }
    .btn_change_timezone {
        font-size: 12px !important;
    }
}
@media screen and (max-width: 1366px) {
    
    .btn_change_timezone {
        font-size: 10px !important;
        margin-left: -70px !important;
    }
    .btn_change_pass {
        font-size: 10px !important;
        margin-top: 5px !important;
    }
    .pr_edit_resp {
        width: 21px !important;
        margin-top: -100% !important;
    }
    .pro_profile_img {
        width: 210px !important;
        height: 190px !important;
        margin-left: 75px !important;
    }
    .resp_edit_btn {
        width: 12% !important;
        margin-top: 0% !important;
    }
    .photoname {
        font-size: 25px !important;
        margin-top: 0px !important;
    }
    .photosubdis {
        font-size: 20px !important;
        margin-left: 20px !important;
    }
}
@media screen and (max-width: 1184px) {
    
    .btn_change_timezone {
        font-size: 10px !important;
        margin-left: -70px !important;
    }
    .btn_change_pass {
        font-size: 10px !important;
        margin-top: 5px !important;
    }
    .pr_edit_resp {
        width: 21px !important;
    }
    .pro_profile_img {
        width: 190px !important;
        height: 170px !important;
        margin-left: 45px !important;
    }
    .resp_edit_btn {
        width: 17% !important;
        margin-top: 0% !important;
    }
    .photoname {
        font-size: 25px !important;
        margin-top: 0px !important;
    }
    .photosubdis {
        font-size: 20px !important;
        margin-left: 20px !important;
    }
}
@media screen and (max-width: 1044px) {
    
    .btn_change_timezone {
        font-size: 10px !important;
        margin-left: -70px !important;
    }
    .btn_change_pass {
        font-size: 10px !important;
        margin-top: 5px !important;
    }
    .pr_edit_resp {
        width: 21px !important;
    }
    .pro_profile_img {
        width: 150px !important;
        height: 140px !important;
        margin-left: 50px !important;
    }
    .resp_edit_btn {
        width: 32% !important;
        margin-top: 0% !important;
    }
    .photoname {
        font-size: 25px !important;
        margin-top: 0px !important;
    }
    .photosubdis {
        font-size: 20px !important;
        margin-left: 20px !important;
    }
}

@media screen and (max-width: 896px) {
    
    .btn_change_timezone {
        font-size: 9px !important;
    }
    .pro_profile_img {
        width: 200px !important;
        height: 210px !important;
        margin-left: 70px !important;
    }
    .photoname {
        font-size: 25px !important;
        margin-top: 0px !important;
        margin-left: -30px !important;
    } 
}
.textbox1{
    width: 50%;
}
.homegrid1{
    margin-left: 10px;
}
.background{
    background: url('../../../assets/img/19.png') 0% 0% no-repeat padding-box;
    opacity: 1;
    background-color: #020922;
    background-size: cover;
    width: 100%;
    background-position: center;
    /* min-height: 100vh; */
    color: #fff;
    display: flex;
    flex-direction: column;

}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    border: none !important;
    outline: none !important;
}
.clagrid{
    background: rgba(255, 253, 253, 0.3) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 0.85;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
}


/* Logout Model */

.Logout-model-container {
    background: #010A26; 
    padding: 20px; 
    width: 28%; 
    border-radius: 0px; 
    max-height: 90%; 
    overflow: auto; 
    position: absolute;

}
@media screen and (max-width: 1024px) {
    .Logout-model-container {
        width: 34% !important; 
    }
}
@media screen and (max-width: 768px) {
    .Logout-model-container {
        width: 48% !important; 
    }
}